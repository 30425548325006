import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@walmart-web/livingdesign-components'
import texts from '../../../../common/texts.json'

const AlternativesButton = ({ onClick }) => {
  const tooltipRef = useRef()
  const [activeTooltip, setActiveTooltip] = useState(true)
  const [isSpinnerLoading, setIsSpinnerLoading] = useState(false)

  const handleAlternatives = async (event) => {
    event.preventDefault()
    setIsSpinnerLoading(true)
    await onClick()
    setIsSpinnerLoading(false)
  }

  useEffect(() => {
    const firstTooltip = document.querySelectorAll('.walmart-alternative-tooltip')[0]
    const isFirstTooltip = tooltipRef.current === firstTooltip

    setActiveTooltip(isFirstTooltip)
    const timer = isFirstTooltip
      ? setTimeout(() => {
          setActiveTooltip(false)
        }, 7000)
      : null

    return () => timer && clearTimeout(timer)
  }, [setActiveTooltip])

  return (
    <button
      className="walmart-alternative-button text-center"
      data-testid="alternative-button-test-id"
      onClick={handleAlternatives}
      onKeyPress={handleAlternatives}
    >
      {activeTooltip && (
        <span className="walmart-alternative-tooltip" ref={tooltipRef}>
          {texts.SOLD_OUT_PRODUCTS.ALTERNATIVES_TOOLTIP}
        </span>
      )}
      {isSpinnerLoading ? (
        <Spinner size="small" className="walmart-alternative-button__spinner" />
      ) : (
        texts.SOLD_OUT_PRODUCTS.GO_TO_ALTERNATIVES
      )}
    </button>
  )
}

AlternativesButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default AlternativesButton
