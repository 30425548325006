import { useSelector } from 'react-redux'

const getPropertyValueFromObject = (propertyChain, object) => propertyChain.split('.').reduce((r, k) => r?.[k], object)

const getCookieValue = (name) => {
  const cookieValue = document.cookie
    .split(';')
    .filter((cookie) => {
      return cookie.trim().startsWith(`${name}=`)
    })
    ?.map((cookie) => cookie.split('=')[1])[0]
  return cookieValue
}

const isEnableFeatureToggleThroughCookies = () => {
  const { enableFeatureToggleThroughCookies = false } = useSelector((state) => state.campaignConfigReducer)
  return enableFeatureToggleThroughCookies
}

const isCookieAllowed = (cookieName) =>
  isEnableFeatureToggleThroughCookies() && cookieName !== 'enableFeatureToggleThroughCookies'

const findFeatureToggle = (featureToggle, configurationFileName) => {
  const configuration = useSelector((state) => state[configurationFileName])
  const featureToggleConfigValue = configuration && getPropertyValueFromObject(featureToggle, configuration)
  const featureToggleCookieValue = getCookieValue(featureToggle)
  return featureToggleCookieValue !== undefined && isCookieAllowed(featureToggle)
    ? featureToggleCookieValue === 'true'
    : featureToggleConfigValue
}

export { findFeatureToggle }
