import React from 'react'
import PropTypes from 'prop-types'
import { priceFormatter, getCssClasses } from '../../../Helpers'
import './index.css'
import texts from '../../../common/texts.json'

const ReferencePrice = ({ isAvailable, referencePrice }) => {
  const getCss = () => getCssClasses('reference-price__price', !isAvailable, 'product-disabled')
  return (
    <div className="d-flex reference-price__container">
      {texts.PRODUCT_PRICES.REGULAR_TEXT}
      <span className={getCss()}>{priceFormatter(referencePrice)}</span>
    </div>
  )
}

ReferencePrice.propTypes = {
  isAvailable: PropTypes.bool.isRequired,
  referencePrice: PropTypes.number.isRequired,
}

export default ReferencePrice
