import { setInitialState, updateCombinables } from './utils'

export const ACTIONS = {
  LAST_PRODUCT_HANDLED: 'last-product-handled',
  RESET_STATE: 'reset-state',
  SET_INITIAL_STATE: 'set-initial-state',
  SYNC_WITH_CART: 'sync-with-cart',
  TOGGLE_COMPONENT: 'toggle-component',
}

export const initialState = {
  brands: [],
  completedPromotions: 0,
  countToPromotion: 0,
  lastProductHandled: '',
  packNormalPrice: 0,
  packPrice: 0,
  packSize: 0,
  products: [],
  showComponent: false,
  showSuccessElements: false,
  totalProductsAdded: 0,
  totalSavings: 0,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_COMPONENT:
      return {
        ...state,
        showComponent: !state.showComponent,
      }
    case ACTIONS.SET_INITIAL_STATE:
      return {
        ...state,
        ...setInitialState(action.payload),
      }
    case ACTIONS.SYNC_WITH_CART:
      return {
        ...state,
        ...updateCombinables(state, action.payload),
      }
    case ACTIONS.LAST_PRODUCT_HANDLED:
      return {
        ...state,
        lastProductHandled: action.payload,
      }
    case ACTIONS.RESET_STATE:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
