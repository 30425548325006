import React from 'react'
import { NavLink } from 'react-router-dom'

import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { getMaxSalesUnitsForProduct, isSod, isProductPack } from '../../../Helpers'
import CartQuantityStepper from './components/CartQuantityStepper'
import useShoppingCart from '../../../functional-components/ShoppingCart/hooks/useShoppingCart'
import constants from '../../../common/constants'
import AlternativesButton from './components/AlternativesButton'
import textTemplate from '../../../common/textTemplate'
import texts from '../../../common/texts.json'
import { findFeatureToggle } from '../../../Helpers/featureToggleHelper'
import './index.css'
import CustomButton from '../../../functional-components/CustomButton'
import getProductPathname from '../../../Helpers/getProductPathname'
import CombinableProducts from '../../../functional-components/CombinableProducts'
import FeatureOnModalButton from '../../../functional-components/FeatureOnModalButton'
import { ORIGINS } from '../../../data-tagging/constants'
import { validateSlotBanner } from '../../../common/advisorUtil'

const ProductCardHomeButton = ({
  campaignConfig,
  hit,
  origin,
  restrictShoppingCart,
  flowType,
  dtSearchEngine,
  searchState,
  bannerSlotInfo,
  index,
}) => {
  const { handleAddToCart, handleRemoveOneFromCart, getProductQuantityInCart, handleAlternativeToSoldOutItem } =
    useShoppingCart()
  const productQuantity = getProductQuantityInCart(hit)
  const listProduct = campaignConfig.groceryBoxSets ? campaignConfig.groceryBoxSets : []
  const newItem = Object.assign({}, hit, { quantity: productQuantity })
  const maxUnits = getMaxSalesUnitsForProduct(listProduct, newItem)
  const hasSalesUnits = maxUnits !== constants.maxQuantity.UNLIMITED
  const skusToFilter = campaignConfig.blackListProductToSell || []
  const showAlternativeProducts = isSod() && findFeatureToggle('showAlternativeProducts', 'campaignConfigReducer')
  const isCombinableProduct = hit?.promotion?.isCombinable && isProductPack(hit.price)

  const handleAddProductToCart = () =>
    handleAddToCart({
      product: hit,
      listProduct,
      origin,
      shouldOpenCart: true,
      flowType,
      dtSearchEngine,
      searchState,
      index,
      bannerSlotInfo,
    })

  const handleRemoveProductFromCart = () => handleRemoveOneFromCart(hit, origin, flowType, dtSearchEngine)

  const handleAlternativesSoldOutItem = async () => handleAlternativeToSoldOutItem(hit, origin, bannerSlotInfo)
  const renderAddToCartButton = () => {
    const { available, sku, variants, slug } = hit
    const hasProductAvailability = available && !skusToFilter.some((value) => value === sku)

    const isProductInCart = productQuantity > 0
    const hasProductVariants = Array.isArray(variants) && variants.length > 0

    if (isCombinableProduct && hasProductAvailability) {
      return (
        <CombinableProducts
          product={hit}
          spaTagMetadata={{ origin: ORIGINS.PLP, query: searchState.query, position: index, flowType }}
        >
          {(childProps) => (
            <FeatureOnModalButton
              variant="product-card"
              LDButtonProps={{ variant: 'primary', size: 'medium' }}
              LDSpinnerProps={{ color: 'gray' }}
              {...childProps}
            >
              {texts.COMBINABLE_PROMOTION.BUTTON_TEXT}
            </FeatureOnModalButton>
          )}
        </CombinableProducts>
      )
    }

    if (hasProductVariants)
      return (
        <NavLink
          to={{
            pathname: getProductPathname({ sku, slug }),
          }}
          className="product-card-button__button product-card-button__nav product-card-button__button--primary"
        >
          {texts.COMMON.GO_TO_PRODUCT}
        </NavLink>
      )

    if (isProductInCart)
      return (
        <CartQuantityStepper
          hit={hit}
          productQuantity={productQuantity}
          origin={origin}
          validateUnit={hasSalesUnits}
          onAddToCart={handleAddProductToCart}
          onRemoveOneFromCart={handleRemoveProductFromCart}
        />
      )

    if (!hasProductAvailability && showAlternativeProducts) {
      return <AlternativesButton onClick={handleAlternativesSoldOutItem} />
    }

    if (!hasProductAvailability)
      return (
        <CustomButton
          title={texts.PRODUCT_DETAIL.SOLD_OUT}
          onClick={() => {}}
          disabled={true}
          className="product-card-button__button product-card-button__button--disabled"
        />
      )

    return (
      <CustomButton
        title={texts.COMMON.ADD}
        onClick={handleAddProductToCart}
        className="product-card-button__button product-card-button__button--primary"
        onKeyPress={handleAddProductToCart}
      />
    )
  }

  const renderUnitsMessage = () => {
    const shouldDisplayMessage = maxUnits >= 1 && !restrictShoppingCart
    const messageClassName = `walmart-add-cart-button-container-alert`

    return shouldDisplayMessage ? (
      <div className={messageClassName}>{textTemplate(texts.PRODUCT_DETAIL.MAX_SALE_PRODUCT_TPL, { maxUnits })}</div>
    ) : null
  }

  const containerClassName = () => `product-card-button__container walmart-add-cart-button-container`

  return (
    <div>
      <div className={containerClassName()}>{renderAddToCartButton()}</div>
      {renderUnitsMessage()}
    </div>
  )
}

const mapStateToProps = (state) => ({
  restrictShoppingCart: state.shoppingCartReducer.restrictShoppingCart,
  bannerSlotInfo: validateSlotBanner(state.advertisingDisplay),
})

export default connect(mapStateToProps)(ProductCardHomeButton)

ProductCardHomeButton.defaultProps = {
  hit: {},
  origin: '',
  campaignConfig: {},
  restrictShoppingCart: false,
  flowType: '',
  dtSearchEngine: '',
  searchState: {},
  bannerSlotInfo: {},
  index: 0,
}

ProductCardHomeButton.propTypes = {
  hit: PropTypes.object,
  origin: PropTypes.string,
  campaignConfig: PropTypes.object,
  restrictShoppingCart: PropTypes.bool,
  flowType: PropTypes.string,
  dtSearchEngine: PropTypes.string,
  searchState: PropTypes.object,
  bannerSlotInfo: PropTypes.object,
  index: PropTypes.number,
}
