import React from 'react'
import PropTypes from 'prop-types'
import { Modal, BottomSheet } from '@walmart-web/livingdesign-components'

import useWindowSize from '../../hooks/useWindowSize'
import './index.css'

const ProductFlatListModal = ({ body, isOpen, onClose, onClosed, size, title, error, allowBackdrop, customClass }) => {
  const { isMobile } = useWindowSize()
  const Wrapper = isMobile && allowBackdrop ? BottomSheet : Modal
  const noHeaderBottomLine = error ? 'no-header-bottom-line' : ''
  const props = {
    ...(size && !isMobile
      ? { size, UNSAFE_className: `${noHeaderBottomLine} ${customClass} product-flat-list-modal` }
      : { UNSAFE_className: `${noHeaderBottomLine} ${customClass} product-flat-list-bottom-sheet` }),
    isOpen,
    onClose,
    onClosed,
    title,
  }

  return <Wrapper {...props}>{body}</Wrapper>
}

ProductFlatListModal.propTypes = {
  body: PropTypes.element,
  error: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onClosed: PropTypes.func.isRequired,
  size: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  allowBackdrop: PropTypes.bool,
  customClass: PropTypes.string,
}

ProductFlatListModal.defaultProps = {
  size: 'medium',
  allowBackdrop: true,
  customClass: '',
}

export default ProductFlatListModal
