import React from 'react'
import { NavLink } from 'react-router-dom'

import PropTypes from 'prop-types'

import './ProductColorIcon.css'

const ProductColorIcon = ({ pathname, color, selected }) => (
  <NavLink
    className={`product-color-icon__link${selected ? ' product-color-icon__link--selected' : ''}`}
    to={{
      pathname,
    }}
  >
    <span
      className="product-color-icon__icon"
      style={{
        backgroundColor: color,
      }}
    />
  </NavLink>
)

export default ProductColorIcon

ProductColorIcon.defaultProps = {
  selected: false,
}
ProductColorIcon.propTypes = {
  pathname: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  selected: PropTypes.bool,
}
