import React from 'react'
import { NavLink } from 'react-router-dom'
import PropTypes from 'prop-types'

import ProductColorIcon from './components/ProductColorIcon'
import getProductPathname from '../../Helpers/getProductPathname'

import './index.css'

const ProductColorSelection = ({ variants, slug, parentSku, attributes, showColorSelection }) => {
  const hasToShowMoreOptions = showColorSelection && variants.length >= 4
  const variantsToShow = hasToShowMoreOptions ? variants.slice(0, 4) : variants

  const moreOptionsIcon = () => (
    <NavLink
      to={{
        pathname: getProductPathname({ sku: parentSku, slug }),
      }}
      className="product-color-selection__more-options-icon"
    >
      +
    </NavLink>
  )

  return (
    <div className="product-color-selection__variants-container" data-testid="product-color-selection-test-id">
      {showColorSelection &&
        variantsToShow.map((variant) => {
          const [variantSize] = variant.sizes.filter(({ size }) => size === attributes.skuSize)
          const defaultSku = variantSize ? variantSize.sku : variant.sizes[0].sku

          return (
            <ProductColorIcon
              key={defaultSku}
              pathname={getProductPathname({ sku: defaultSku, slug })}
              color={variant.colorCode}
              selected={variant.color === attributes.skuColor}
            />
          )
        })}
      {hasToShowMoreOptions && moreOptionsIcon()}
    </div>
  )
}

export default ProductColorSelection

ProductColorIcon.defaultProps = {
  showColorSelection: false,
}

ProductColorSelection.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      colorCode: PropTypes.string.isRequired,
      sizes: PropTypes.array.isRequired,
      color: PropTypes.string,
      defaultImage: PropTypes.string,
    })
  ).isRequired,
  attributes: PropTypes.objectOf(
    PropTypes.shape({
      skuSize: PropTypes.string.isRequired,
      skuColor: PropTypes.array.isRequired,
    })
  ).isRequired,
  slug: PropTypes.string.isRequired,
  parentSku: PropTypes.string.isRequired,
  showColorSelection: PropTypes.bool,
}
