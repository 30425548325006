import React from 'react'
import PropTypes from 'prop-types'

import { CheckCircleFill as CheckCircleFillIcon } from '@walmart-web/livingdesign-icons'

import { priceFormatter } from '../../../../Helpers'
import textTemplate from '../../../../common/textTemplate'
import texts from '../../../../common/texts.json'

import './index.css'

const ContentHeader = ({ data, title }) => {
  const {
    brands,
    completedPromotions,
    countToPromotion,
    packNormalPrice,
    packPrice,
    packSize,
    showSuccessElements,
    totalProductsAdded,
  } = data
  const hasMoreThanOnePromotion = completedPromotions > 1
  const showMotivator = totalProductsAdded > 0

  return (
    <div className="combinable-content-header d-flex flex-column justify-content-between">
      <h2 className="combinable-content-header__title m-0">{title}</h2>
      <div className="combinable-content-header__body d-flex justify-content-between">
        <div className="d-flex flex-column justify-content-between combinable-content-header__info h-100">
          <div className="combinable-content-header-brand">
            <p className="m-0">
              {texts.COMBINABLE_PROMOTION.PROMOTION_BRANDS} <b>{brands.join(', ')}</b>
            </p>
            {completedPromotions > 0 && (
              <p className="m-0">
                <CheckCircleFillIcon />{' '}
                {textTemplate(
                  hasMoreThanOnePromotion
                    ? texts.COMBINABLE_PROMOTION.PROMOTION_AMOUNT_MSG_PLURAL
                    : texts.COMBINABLE_PROMOTION.PROMOTION_AMOUNT_MSG_SINGULAR,
                  { completedPromotions }
                )}
              </p>
            )}
          </div>
          <div className="combinable-content-header-pricing h-100 d-flex flex-column justify-content-center">
            <h4 className="m-0 mt-3">{`${packSize} x ${priceFormatter(packPrice)}`}</h4>
            {packNormalPrice && (
              <span>
                {textTemplate(texts.COMBINABLE_PROMOTION.PROMOTION_NORMAL_PRICE, {
                  price: priceFormatter(packNormalPrice),
                })}
              </span>
            )}
          </div>
        </div>
        {showMotivator && (
          <div
            className={`combinable-content-header__counter d-flex justify-content-center align-items-center ${
              showSuccessElements || countToPromotion === 0 ? 'bg-white' : ''
            }`}
          >
            {showSuccessElements && <CheckCircleFillIcon size="large" />}
            {countToPromotion > 0 && !showSuccessElements && (
              <div className="d-flex flex-column justify-content-center align-items-center">
                <span>
                  {textTemplate(texts.COMBINABLE_PROMOTION.PROMOTION_POTENCIATOR_COUNT, {
                    countToPromotion,
                    packSize,
                  })}
                </span>
                <span>
                  {textTemplate(texts.COMBINABLE_PROMOTION.PROMOTION_POTENCIATOR_MSG, {
                    quantity: packSize - countToPromotion,
                  })}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

ContentHeader.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
}

export default ContentHeader
