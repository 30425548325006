import React, { useEffect, useReducer } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { reducer, initialState, ACTIONS } from './reducer'
import { useClient } from '../../hooks/useClient'
import { useIsTenant } from '../../contexts/TenantContext'
import { useShoppingCartContext } from '../ShoppingCart/context/ShoppingCartContext'
import ClientAPI from '../../common/ClientAPI'
import CombinablesContent from './components/Content'
import constants from '../../common/constants'
import useStore from '../../hooks/useStore'
import { sendSPAClickEvent, sendTaggingClick } from '../../common/advisorSPAUtil'

const CombinableProducts = ({ product, spaTagMetadata, children }) => {
  const clientAPI = new ClientAPI()
  const { sku, promotion } = product
  const { shoppingCartItems } = useShoppingCartContext()
  const [state, dispatch] = useReducer(reducer, initialState)
  const [{ data, loading, error }, fetchCombinables] = useClient()
  const storeId = useStore()?.toString()
  const showError = error || data?.products?.length === 0
  const isCatex = useIsTenant(constants.tenant.CATEX)
  const { goToPayMessageConfig = {} } = useSelector((state) => state.campaignConfigReducer)
  const { digitLimitToCarryConfig = {} } = goToPayMessageConfig || {}
  const { limit: quantityLimit } = digitLimitToCarryConfig

  const toggleCombinables = () => {
    dispatch({ type: ACTIONS.TOGGLE_COMPONENT })
  }

  useEffect(() => {
    if (showError) {
      return toggleCombinables()
    }
    if (data) {
      const combinable = data.products.find((obj) => obj.sku === product.sku)
      if (combinable) {
        combinable.tracking = product.tracking || null
        combinable.description = product.description
        combinable.attributes = product.attributes
        combinable.ID = product.ID
      }

      dispatch({
        type: ACTIONS.SET_INITIAL_STATE,
        payload: { combinableProducts: data.products, shoppingCartItems, isCatex, quantityLimit, spaTagMetadata },
      })
    }
  }, [data, error])

  const callSponsoredProductApi = async () => {
    if (product.tracking) {
      sendSPAClickEvent(product)
      sendTaggingClick(product, spaTagMetadata)
    }
  }

  const getCombinables = () => {
    callSponsoredProductApi()

    return fetchCombinables(
      clientAPI.getCombinables({
        sku,
        promotionId: promotion.id,
        ...(storeId && { storeId }),
      })
    )
  }

  return (
    <>
      <CombinablesContent
        onClose={toggleCombinables}
        data={state}
        dispatch={dispatch}
        showError={showError}
        spaTagMetadata={spaTagMetadata}
      />
      {children({
        isLoading: loading,
        onClick: getCombinables,
        onKeyPress: getCombinables,
      })}
    </>
  )
}

CombinableProducts.propTypes = {
  product: PropTypes.object.isRequired,
  spaTagMetadata: PropTypes.object.isRequired,
  children: PropTypes.func,
}

CombinableProducts.defaultProps = {
  children: () => {},
}

export default React.memo(CombinableProducts)
