import React from 'react'
import PropTypes from 'prop-types'
import { useSnackbar } from '@walmart-web/livingdesign-components'

import { ACTIONS } from '../reducer'
import ErrorMessage from '../../ProductFlatListModal/components/ErrorMessage'
import ContentBody from './ContentBody'
import ContentHeader from './ContentHeader'
import ProductFlatListModal from '../../ProductFlatListModal'
import textTemplate from '../../../common/textTemplate'

import texts from '../../../common/texts.json'

const CombinablesContent = ({ data, dispatch, showError, spaTagMetadata }) => {
  const { addSnack } = useSnackbar()

  const handleOnClose = () => {
    dispatch({ type: ACTIONS.TOGGLE_COMPONENT })
  }

  const handleOnClosed = () => {
    const { completedPromotions, totalProductsAdded } = data
    if (totalProductsAdded > 0 && completedPromotions < 1) {
      addSnack({
        message: textTemplate(texts.COMBINABLE_PROMOTION.PROMOTION_UNCOMPLETED, { totalProductsAdded }),
      })
    }
    dispatch({ type: ACTIONS.RESET_STATE })
  }

  const getContent = () => {
    const content = {
      header: undefined,
      body: undefined,
    }
    if (showError) {
      content.body = (
        <ErrorMessage
          icon="/catalogo/images/error-message-media.svg"
          title={texts.ERROR_MODAL.TITLE}
          subTitle={texts.ERROR_MODAL.PROMOTIONS_SERVICE_ERROR}
          onClick={handleOnClose}
          buttonLabel={texts.ERROR_MODAL.BACK_BUTTON_TEXT}
        />
      )
      return content
    }
    content.header = <ContentHeader title={texts.COMBINABLE_PROMOTION.MODAL_TITLE} data={data} />
    content.body = <ContentBody data={data} dispatch={dispatch} spaTagMetadata={spaTagMetadata} />
    return content
  }

  const { header, body } = getContent()

  const props = {
    body,
    error: showError,
    isOpen: data.showComponent,
    onClose: handleOnClose,
    onClosed: handleOnClosed,
    size: showError ? 'small' : 'medium',
    title: showError ? <></> : header,
  }

  return <ProductFlatListModal {...props} />
}

CombinablesContent.propTypes = {
  dispatch: PropTypes.func.isRequired,
  data: PropTypes.object,
  showError: PropTypes.bool,
  spaTagMetadata: PropTypes.object,
}

export default CombinablesContent
