import React from 'react'
import PropTypes from 'prop-types'
import { getCssClasses } from '../../../Helpers'
import './index.css'

const DiscountPercentage = ({ isAvailable, discountPercentage }) => {
  const getCss = () =>
    getCssClasses('discount-percentage__percentage-saved', !isAvailable, 'discount-percentage__percentage-disabled')
  return <div className={getCss()}>{`${discountPercentage}%`}</div>
}

DiscountPercentage.propTypes = {
  isAvailable: PropTypes.bool.isRequired,
  discountPercentage: PropTypes.number.isRequired,
}

export default DiscountPercentage
