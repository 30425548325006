import PropTypes from 'prop-types'
import React from 'react'

import { Tag } from '@walmart-web/livingdesign-components'
import { useSelector } from 'react-redux'
import { useTagsContext } from '../context/TagsContext'

import './CampaignTag.css'

const CampaignTag = ({ tags, isAvailable = false, classNames }) => {
  const { enableCampaignMode } = useSelector((state) => state.campaignConfigReducer) || {}
  const { tagsConfig } = useTagsContext()

  if (!enableCampaignMode) return <></>

  const { campaign = {} } = tagsConfig || {}
  const { campaignTags = [] } = tags || {}
  const [tag] = campaignTags.filter((tagName) => campaign[tagName])

  const hasCustomStyle = campaign[tag]?.style?.length > 0

  return (
    <div
      className={`tags__campaign-tag-container ${classNames} ${
        !isAvailable ? 'tags__campaign-tag-container--opaque' : ''
      }`}
    >
      {tag && (
        <Tag
          variant="primary"
          UNSAFE_className={`tags tags__campaign-tag${hasCustomStyle ? `--${tag}` : ''}`}
          data-testid="campaign-tag-test-id"
        >
          {campaign[tag].label}
        </Tag>
      )}
    </div>
  )
}

export default CampaignTag

CampaignTag.defaultProps = {
  isAvailable: false,
  classNames: '',
}
CampaignTag.propTypes = {
  tags: PropTypes.array.isRequired,
  classNames: PropTypes.string,
  isAvailable: PropTypes.bool,
}
