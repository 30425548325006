import React from 'react'
import PropTypes from 'prop-types'
import { Link, Modal } from '@walmart-web/livingdesign-components'
import { InfoCircle as InfoCircleIcon } from '@walmart-web/livingdesign-icons'
import texts from '../../../common/texts.json'
import { Airplane } from '../../CustomSVGIcons'

import './index.css'
import RenderInnerHTML from '../../../components/RenderInnerHTML'

const InternationalSellerModal = ({ isOpen, onClose }) => {
  const { MODAL } = texts.INTERNATIONAL_SELLER

  const footer = () => (
    <div className={'international-seller-modal__footer'}>
      <InfoCircleIcon size="small" />
      <Link href={MODAL.URL_TERMS_CONDITIONS}>{MODAL.URL_DESCRIPTION}</Link>
    </div>
  )

  return (
    <Modal
      UNSAFE_className="international-seller-modal"
      isOpen={isOpen}
      onClose={onClose}
      title={<></>}
      size={'medium'}
      actions={footer()}
    >
      <div className={'international-seller-modal__container'}>
        <Airplane size={'large'} />
        <div className={'international-seller-modal__title'}>{MODAL.TITLE}</div>
        <div className={'international-seller-modal__conditions-list'}>
          <ul>
            <li>
              <RenderInnerHTML html={MODAL.CONDITION_1} />
            </li>
            <li>
              <RenderInnerHTML html={MODAL.CONDITION_2} />
            </li>
            <li>
              <RenderInnerHTML html={MODAL.CONDITION_3} />
            </li>
            <li>
              <RenderInnerHTML html={MODAL.CONDITION_4} />
            </li>
          </ul>
        </div>
      </div>
    </Modal>
  )
}

export default InternationalSellerModal

InternationalSellerModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}
