import { SET_LAST_SKU } from '../types'

export default (skuClicked) => (dispatch) => {
  dispatch({
    type: SET_LAST_SKU,
    payload: {
      skuClicked,
    },
  })
}
