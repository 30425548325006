import React from 'react'
import PropTypes from 'prop-types'
import { List, ListItem, Button } from '@walmart-web/livingdesign-components'
import QuantityStepper from '../QuantityStepper'

import LazyLoadImageContainer from '../../components/LazyLoadImageContainer'
import texts from '../../common/texts.json'
import { priceFormatter } from '../../Helpers'

import './index.css'

const ProductFlatList = ({ products, onAddToCart, onRemoveFromCart, renderExtraInfo }) => {
  const getPhoto = ({ sku, displayName, images }) => (
    <LazyLoadImageContainer
      id={`lazy-img-${sku}`}
      className="img-fluid"
      alt={`${sku} - ${displayName}`}
      sku={sku}
      hitImage={images?.smallImage}
    />
  )

  const getBtn = (product) => {
    if (product.quantity > 0) {
      return (
        <QuantityStepper
          product={product}
          onIncrease={() => onAddToCart(product)}
          onDecrease={() => onRemoveFromCart(product)}
        />
      )
    }
    return (
      <Button UNSAFE_className="add-to-cart-wrapper__btn" onClick={() => onAddToCart(product)} isFullWidth>
        {texts.COMMON.ADD}
      </Button>
    )
  }

  return (
    <List UNSAFE_className="product-flat-list">
      {products.map((product, i) => {
        return (
          <ListItem leading={getPhoto(product)} key={`product-${i}`}>
            <div className="h-100">
              <div className="list-item__title">
                <b>{product.brand} </b>
                {product.displayName}
              </div>
              <div className={`btn-wrapper mt-3 d-flex justify-content-between align-items-center`}>
                <span className="d-block">
                  <b>{priceFormatter(product?.price?.BasePriceSales)}</b>
                </span>
                <div className="add-to-cart-wrapper">{getBtn(product)}</div>
              </div>
              {renderExtraInfo?.(product)}
            </div>
          </ListItem>
        )
      })}
    </List>
  )
}

ProductFlatList.propTypes = {
  products: PropTypes.array.isRequired,
  onAddToCart: PropTypes.func.isRequired,
  onRemoveFromCart: PropTypes.func.isRequired,
  renderExtraInfo: PropTypes.func,
}

export default React.memo(ProductFlatList)
