import React, { useCallback, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Button } from '@walmart-web/livingdesign-components'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'

import { ACTIONS } from '../../reducer'
import { priceFormatter } from '../../../../Helpers'
import { useShoppingCartContext } from '../../../ShoppingCart/context/ShoppingCartContext'
import ProductFlatList from '../../../ProductFlatList'
import ProductFlatListLabel from '../../../ProductFlatList/components/Label'
import RenderInnerHTML from '../../../../components/RenderInnerHTML'
import texts from '../../../../common/texts.json'
import textTemplate from '../../../../common/textTemplate'
import useShoppingCart from '../../../ShoppingCart/hooks/useShoppingCart'

import './index.css'
import { SUB_ORIGINS } from '../../../../data-tagging'

const ContentBody = ({ data, dispatch, spaTagMetadata }) => {
  const { lastProductHandled, products, showSuccessElements, totalSavings } = data
  const { handleAddToCart, handleRemoveOneFromCart } = useShoppingCart()
  const { shoppingCartItems } = useShoppingCartContext()
  const [lastProductHandledSku] = lastProductHandled.split('-')
  const bannerSlotInfo = useSelector((s) => s.advertisingDisplay)

  useEffect(() => {
    if (lastProductHandled) {
      dispatch({ type: ACTIONS.SYNC_WITH_CART, payload: shoppingCartItems })
    }
  }, [dispatch, lastProductHandled, shoppingCartItems])

  const addToCart = useCallback(
    (product) => {
      handleAddToCart({
        product,
        bannerSlotInfo,
        flowType: spaTagMetadata.flowType,
        origin: spaTagMetadata?.origin,
        subOrigin: SUB_ORIGINS.COMBINABLE,
        searchState: { query: spaTagMetadata?.query },
        index: spaTagMetadata?.position,
      })
      dispatch({ type: ACTIONS.LAST_PRODUCT_HANDLED, payload: `${product.sku}-${uuidv4()}` })
    },
    [bannerSlotInfo, dispatch, handleAddToCart]
  )

  const removeFromCart = useCallback(
    (product) => {
      handleRemoveOneFromCart(product, spaTagMetadata?.origin, spaTagMetadata.flowType)
      dispatch({ type: ACTIONS.LAST_PRODUCT_HANDLED, payload: `${product.sku}-${uuidv4()}` })
    },
    [dispatch, handleRemoveOneFromCart]
  )

  const renderItemLabel = useCallback(
    (product) => {
      const showSavings = showSuccessElements && lastProductHandledSku === product.sku
      if (showSavings) {
        return (
          <div className={`${showSavings ? 'mt-3' : ''}`}>
            <ProductFlatListLabel>
              {textTemplate(texts.COMBINABLE_PROMOTION.PROMOTION_SAVING_MESSAGE, {
                totalSavings: priceFormatter(totalSavings),
              })}
            </ProductFlatListLabel>
          </div>
        )
      }
      return <></>
    },
    [lastProductHandledSku, showSuccessElements, totalSavings]
  )

  return (
    <div className="combinable-content-body">
      <div className="combinable-content-body__scrollable">
        <ProductFlatList
          renderExtraInfo={renderItemLabel}
          onAddToCart={addToCart}
          onRemoveFromCart={removeFromCart}
          products={products}
        />
      </div>
      {products.length === 1 && (
        <div className="combinable-content-body__message-container">
          <p className="m-0">
            <RenderInnerHTML html={texts.COMBINABLE_PROMOTION.PROMOTION_WITH_ONE_PRODUCT_AVAILABLE} />
          </p>
        </div>
      )}
      {showSuccessElements && (
        <div className="combinable-content-body__button-container d-flex justify-content-center align-items-center">
          <Button variant="primary" isFullWidth size="medium" onClick={() => dispatch({ type: ACTIONS.RESET_STATE })}>
            {texts.COMBINABLE_PROMOTION.PROMOTION_COMPLETED_BUTTON_TEXT}
          </Button>
        </div>
      )}
    </div>
  )
}

ContentBody.propTypes = {
  data: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  spaTagMetadata: PropTypes.object,
}

export default ContentBody
