/**
 * Rct Card
 */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
// rct card heading
import { RctCardHeading } from '../RctCardHeading'

export default class RctCard extends Component {
  render() {
    const { children, customClasses, heading, headingCustomClasses, colClasses, customStyles } = this.props
    return (
      <div className={colClasses}>
        <div className={`rct-block ${customClasses || ''}`} style={customStyles}>
          {heading && <RctCardHeading title={heading} customClasses={headingCustomClasses} />}
          {children}
        </div>
      </div>
    )
  }
}

RctCard.defaultProps = {
  children: [],
  customClasses: '',
  heading: '',
  headingCustomClasses: '',
  colClasses: '',
  customStyles: {},
}

RctCard.propTypes = {
  children: PropTypes.any,
  customClasses: PropTypes.string,
  heading: PropTypes.string,
  headingCustomClasses: PropTypes.string,
  colClasses: PropTypes.string,
  customStyles: PropTypes.object,
}
