const getTotals = (products) =>
  products.reduce(
    (accum, current) => {
      return {
        totalProductsAdded: accum.totalProductsAdded + current.quantity,
        totalOriginalPrices: accum.totalOriginalPrices + current.price.BasePriceSales * current.quantity,
      }
    },
    { totalProductsAdded: 0, totalOriginalPrices: 0 }
  )

const getCompletedPromotions = (totalProductsAdded, packSize) => Math.trunc(totalProductsAdded / packSize)

const getPackOriginals = (products) => {
  return products.reduce(
    (acum, current) => ({
      basePrices: acum.basePrices.add(current.price.BasePriceSales),
      brands: acum.brands.add(current.brand),
    }),
    { basePrices: new Set(), brands: new Set() }
  )
}

const getPackInfo = (products) => {
  const [{ price }] = products
  const { packPrice, packSize } = price
  const { basePrices, brands } = getPackOriginals(products)
  const packNormalPrice = basePrices.size === 1 ? [...basePrices][0] : ''

  return {
    brands: [...brands],
    packNormalPrice,
    packPrice,
    packSize,
  }
}

const syncProductsWithCart = ({ combinableProducts, shoppingCartItems, isCatex, quantityLimit }) => {
  return combinableProducts.map((combinable) => {
    const combinableInCart = shoppingCartItems.find(
      (v) => v?.promotion?.promotionId === combinable?.promotion?.promotionId && v.sku === combinable.sku
    )
    combinable.quantity = combinableInCart ? combinableInCart.quantity : 0
    if (isCatex && quantityLimit) {
      combinable.max = quantityLimit
    }
    return combinable
  })
}

export const updateCombinables = (state, shoppingCartItems) => {
  const { products: productsFromState, packPrice, packSize, totalProductsAdded: totalProductsAddedFromState } = state
  const productsSync = syncProductsWithCart({ combinableProducts: productsFromState, shoppingCartItems })
  const { totalProductsAdded, totalOriginalPrices } = getTotals(productsSync)
  const completedPromotions = getCompletedPromotions(totalProductsAdded, packSize)

  const countToPromotion = totalProductsAdded - completedPromotions * packSize
  const promotionEarned = countToPromotion === 0
  const totalSavings = promotionEarned ? totalOriginalPrices - packPrice * completedPromotions : 0
  const isAdding = totalProductsAddedFromState < totalProductsAdded

  return {
    completedPromotions,
    countToPromotion,
    products: productsSync,
    showSuccessElements: promotionEarned && isAdding,
    totalProductsAdded,
    totalSavings,
  }
}

export const setInitialState = ({ combinableProducts, shoppingCartItems = [], isCatex, quantityLimit }) => {
  const { brands, packNormalPrice, packPrice, packSize } = getPackInfo(combinableProducts)
  const products = syncProductsWithCart({ combinableProducts, shoppingCartItems, isCatex, quantityLimit })
  const { totalProductsAdded } = getTotals(products)
  const completedPromotions = getCompletedPromotions(totalProductsAdded, packSize)

  return {
    products,
    brands,
    packNormalPrice,
    packPrice,
    packSize,
    completedPromotions,
    totalProductsAdded,
    showComponent: true,
  }
}
