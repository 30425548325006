import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import useOnScreen from '../useOnScreen'
import { sendSPAViewEvent } from '../../../common/advisorSPAUtil'

const AdvertisingProduct = ({ children, hit, searchState, position }) => {
  const ref = useRef(null)
  const isIntersecting = useOnScreen(ref)
  const [eventView, eventViewSent] = useState(false)

  useEffect(() => {
    if (isIntersecting && !eventView) {
      const startTime = Date.now()
      const timeoutId = setTimeout(() => {
        sendSPAViewEvent(hit, startTime, { query: searchState.query, position })
        eventViewSent(true)
      }, 1000)

      return () => {
        clearTimeout(timeoutId)
      }
    }
  }, [isIntersecting, eventView])

  return <div ref={ref}>{children}</div>
}

AdvertisingProduct.propTypes = {
  children: PropTypes.node.isRequired,
  hit: PropTypes.object,
  searchState: PropTypes.object,
  position: PropTypes.number,
}

export default AdvertisingProduct
