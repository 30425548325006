/* eslint-disable import/prefer-default-export */
import { useEffect, useState } from 'react'

export const useClient = () => {
  const [data, setData] = useState()
  const [call, setCall] = useState(undefined)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      setError(false)
      setLoading(true)

      try {
        if (call) {
          const result = await call

          setData(result)
        }
      } catch (error) {
        setError(true)
      }

      setLoading(false)
    }

    fetchData()
  }, [call])

  return [{ data, loading, error }, setCall]
}
