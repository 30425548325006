import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { useIsTenant } from '../contexts/TenantContext'
import { useShoppingCartContext } from '../functional-components/ShoppingCart/context/ShoppingCartContext.js'
import constants from '../common/constants'
import getConfig from '../Helpers/getConfig'

const useStore = () => {
  const [storeId, setStoreId] = useState()
  const { store: sodStoreId } = useShoppingCartContext()
  const selectedWalstoreStore = useSelector((state) => state.selectedWalstoreStore)
  const pickupStore = useSelector((state) => state.pickupStore)

  const isWalstore = getConfig('isWalstore') === 'true'
  const isSod = useIsTenant(constants.tenant.SOD)
  const isPickupSelected = !!pickupStore?.id

  useEffect(() => {
    const getStoreId = () => {
      switch (true) {
        case isSod:
          return sodStoreId?.id || constants.stores.DEFAULT_STORE_ID
        case isWalstore:
          return selectedWalstoreStore?.id
        case isPickupSelected:
          return pickupStore?.id
        default:
          return ''
      }
    }
    setStoreId(getStoreId())
  }, [isPickupSelected, isSod, isWalstore, sodStoreId, pickupStore, selectedWalstoreStore])

  return storeId
}

export default useStore
