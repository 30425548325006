import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './index.css'
import InternationalSellerModal from './Modal'
import texts from '../../common/texts.json'
import { Airplane } from '../CustomSVGIcons'

const InternationalSeller = ({ theme, showModal, setParentModalVisible }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { INTERNATIONAL_SELLER } = texts

  const openModal = () => {
    setIsOpen(true)
    setParentModalVisible && setParentModalVisible(false)
  }

  const closeModal = () => {
    setIsOpen(false)
    setParentModalVisible && setParentModalVisible(true)
  }

  return (
    <div className="international-seller fs-12">
      <div className={`international-seller__container--${theme} mb-3`}>
        <div className="mr-2 international-seller__icon">
          <Airplane />
        </div>
        <p className="international-seller__message">
          {INTERNATIONAL_SELLER.BASE}
          {showModal && <button onClick={openModal}>{INTERNATIONAL_SELLER.DETAILS}</button>}
        </p>
      </div>

      {showModal && <InternationalSellerModal isOpen={isOpen} onClose={closeModal} />}
    </div>
  )
}

InternationalSeller.defaultProps = {
  theme: 'default',
  showModal: true,
  setParentModalOpen: () => {},
}

InternationalSeller.propTypes = {
  theme: PropTypes.oneOf(['default', 'blue']),
  showModal: PropTypes.bool,
  setParentModalOpen: PropTypes.func,
}

export default InternationalSeller
