import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@walmart-web/livingdesign-components'

const ModalErrorMessage = ({ icon, onClick, subTitle, title, buttonLabel }) => {
  return (
    <div className="product-flat-list-error-message d-flex justify-content-center">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <img className="product-flat-list-error-message__img" src={icon} alt={'Error Message'} />
        <div className="product-flat-list-error-message__content">
          <h3 className="product-flat-list-error-message__title text-center">{title}</h3>
          <p className="product-flat-list-error-message__subtitle m-0 text-center">{subTitle}</p>
        </div>
        <Button className="product-flat-list-error-message__btn" variant="primary" size="medium" onClick={onClick}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  )
}

ModalErrorMessage.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func,
  subTitle: PropTypes.string,
  buttonLabel: PropTypes.string,
  title: PropTypes.string,
}

export default ModalErrorMessage
