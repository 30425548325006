import React from 'react'
import PropTypes from 'prop-types'

import { Tag } from '@walmart-web/livingdesign-components'
import { Truck as TruckIcon, Store as StoreIcon } from '@walmart-web/livingdesign-icons'
import { useTagsContext } from '../context/TagsContext'
import './DeliveryTag.css'

const icons = {
  truck: <TruckIcon className={'tags__delivery-tag-icon'} />,
  store: <StoreIcon className={'tags__delivery-tag-icon'} />,
}

const DeliveryTag = ({ tag }) => {
  const { tagsConfig } = useTagsContext()
  const { delivery = {} } = tagsConfig || {}

  const tagConfig = delivery[tag]
  const tagColor = tagConfig?.color || 'gray'
  const tagIcon = icons[tagConfig?.icon] || icons.truck

  if (!tagConfig) {
    return <></>
  }

  return (
    <div className={'tags__delivery-tag-container'}>
      <Tag
        variant="tertiary"
        color={tagColor}
        UNSAFE_className={`tags__delivery-tag--${tag}`}
        leading={tagIcon}
        data-testid={'delivery-tag-test-id'}
      >
        {tagConfig.label}
      </Tag>
    </div>
  )
}

export default DeliveryTag

DeliveryTag.defaultProps = {
  tag: '',
}

DeliveryTag.propTypes = {
  tag: PropTypes.string.isRequired,
}
