import React from 'react'
import { PropTypes } from 'prop-types'
import texts from '../../../../src/common/texts.json'
import './index.css'

const InlineRating = ({ productId, showCommentButton, dataBvShow }) => {
  const handleCommentClick = () => {
    document.getElementById('bv-reviews').scrollIntoView({ behavior: 'smooth' })
  }

  const CommentButton = () => (
    <button className="bv-comment-button" onClick={handleCommentClick}>
      {' '}
      {texts.PRODUCT_DETAIL.GO_TO_REVIEWS}{' '}
    </button>
  )

  return (
    <div className="tag-bv d-none">
      <div className="bv-ir-inner-container">
        <div
          data-testid="inline-rating-div"
          data-bv-show={dataBvShow}
          data-bv-product-id={productId}
          data-bv-redirect-url={`http://www.lider.cl/catalogo/product/sku/${productId}`}
        ></div>

        {showCommentButton && <CommentButton />}
      </div>
    </div>
  )
}

InlineRating.defaultProps = {
  showCommentButton: true,
  dataBvShow: 'inline_rating',
}
InlineRating.propTypes = {
  dataBvShow: PropTypes?.string,
  productId: PropTypes.string,
  showCommentButton: PropTypes.bool,
}

export default InlineRating
