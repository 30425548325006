import React from 'react'
import PropTypes from 'prop-types'

import './index.css'

const ProductFlatListLabel = ({ children, variant }) => {
  return (
    <div data-testid="product-flat-list-label" className={`product-flat-list__label ${variant}`}>
      {children}
    </div>
  )
}

ProductFlatListLabel.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  variant: PropTypes.oneOf(['primary']).isRequired,
}

ProductFlatListLabel.defaultProps = {
  variant: 'primary',
}

export default ProductFlatListLabel
