import React from 'react'
import PropTypes from 'prop-types'

import { Tag } from '@walmart-web/livingdesign-components'
import { useTagsContext } from '../context/TagsContext'
import texts from '../../../common/texts.json'

import './AttributeTag.css'

const AttributeTag = ({ tags, isAvailable = false, classNames }) => {
  const { tagsConfig } = useTagsContext()
  const { attribute = {} } = tagsConfig || {}
  const { attributeTags = [] } = tags || {}
  const [tag] = attributeTags.filter((tagName) => attribute[tagName])

  return (
    <div
      className={`tags__attribute-tag-container ${classNames} ${
        !isAvailable ? 'tags__attribute-tag-container--opaque' : ''
      }`}
    >
      {(tag || !isAvailable) && (
        <Tag
          variant="secondary"
          UNSAFE_className="tags"
          data-testid={`${!isAvailable ? 'without-stock-attribute-tag-test-id' : 'attribute-tag-test-id'}`}
        >
          {isAvailable ? attribute[tag].label : texts.PRODUCT_DETAIL.SOLD_OUT}
        </Tag>
      )}
    </div>
  )
}

export default AttributeTag

AttributeTag.defaultProps = {
  isAvailable: false,
  tags: {},
  classNames: '',
}

AttributeTag.propTypes = {
  tags: PropTypes.array.isRequired,
  isAvailable: PropTypes.bool,
  classNames: PropTypes.string,
}
