import React from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { getCssClasses } from '../../../Helpers'
import './index.css'
import { ORIGINS } from '../../../common/GoogleTagManager'

const SponsoredLabel = ({ isSponsored, isAvailable, origin }) => {
  const isSPAEnabled = useSelector((state) => state.campaignConfigReducer.isSPAEnabled)
  const availableOrigins = [ORIGINS.SEARCH, ORIGINS.PLP, ORIGINS.HOME_PAGE]
  if (!isSPAEnabled) return null
  const showLabel = isSponsored && isAvailable
  const getCss = () => getCssClasses('sponsored-label__label-visible', !showLabel, 'sponsored-label__label-hidden')
  if (availableOrigins.includes(origin) || origin.startsWith('home_carousel_')) {
    return <div className="sponsored-label__container">{showLabel && <div className={getCss()}>Patrocinado</div>}</div>
  } else {
    return ''
  }
}

SponsoredLabel.propTypes = {
  isSponsored: PropTypes.object,
  isAvailable: PropTypes.bool.isRequired,
  origin: PropTypes.string,
}

SponsoredLabel.defaultProps = {
  origin: '',
}

export default SponsoredLabel
