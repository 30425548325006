import { useState, useEffect } from 'react'

// Hook https://developer.mozilla.org/es/docs/Web/API/Intersection_Observer_API
function useOnScreen(ref, rootMargin = '0px', threshold = 0.5) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(null)
  useEffect(() => {
    const curr = ref.current
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      }, {
        rootMargin,
        threshold,
      },
    )
    if (ref.current) {
      observer.observe(curr)
    }
    return () => {
      observer.unobserve(curr)
    }
  }, [ref, rootMargin, threshold]) // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting
}

export default useOnScreen
