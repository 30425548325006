import React from 'react'
import PropTypes from 'prop-types'
import { Button, Spinner } from '@walmart-web/livingdesign-components'

import './index.css'

const FeatureOnModalButton = ({
  children,
  disabled,
  isLoading,
  LDButtonProps,
  LDSpinnerProps,
  onClick,
  onKeyPress,
  variant,
}) => {
  return (
    <Button
      className={`feature_button ${variant}`}
      onClick={onClick}
      onKeyPress={onKeyPress}
      disabled={disabled}
      {...LDButtonProps}
    >
      {isLoading ? <Spinner size="small" {...LDSpinnerProps} /> : children}
    </Button>
  )
}

FeatureOnModalButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  LDButtonProps: PropTypes.object,
  LDSpinnerProps: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  variant: PropTypes.oneOf(['product-card', 'product-detail', 'product-detail-fixed-add-to-cart']).isRequired,
}

FeatureOnModalButton.defaultProps = {
  disabled: false,
  isLoading: false,
  onClick: () => {},
  onKeyPress: () => {},
}

export default FeatureOnModalButton
